import { cloneElement, useRef } from 'react';

import SplitText from '~/components/molecules/SplitText/SplitText';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { PortableTextOptionsCategory } from '../../../PortableTextOptions.types';
import { BlockBaseProps } from './BlockBase.types';

const BlockBase = (props: BlockBaseProps) => {
  const {
    children,
    className,
    refs,
    styleKey,
    groupStyleKey,
    tagName,
    index,
    value,
    blockRefs,
  } = props;
  const RenderTag: string = tagName || 'div';
  const defaultStyle = styleKey ? styles[styleKey] : '';

  const renderClass = cn(defaultStyle, className) || null;
  // Note: empty attributes don't make sense but it's required by the type checker
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attributes: any = {};

  const $element = useRef<HTMLElement>();

  const shouldSplit = typeof blockRefs !== 'undefined';

  const renderSplitText = (i: number, text: string, markType?: string) => {
    // create an object for each block that contains all the refs plus information about the block
    if (blockRefs?.current && !blockRefs.current?.[index])
      blockRefs.current[index] = {
        groupStyleKey: groupStyleKey,
        category: value?._type as PortableTextOptionsCategory,
        parentRef: $element,
        markType: markType,
        wordEls: [],
        letterEls: [],
      };
    if (shouldSplit) {
      return (
        blockRefs && (
          <SplitText key={i} blockRefs={blockRefs} index={index}>
            {text}
          </SplitText>
        )
      );
    } else {
      return text;
    }
  };

  const text = Array.isArray(children)
    ? children
        ?.map((child, i) => {
          if (typeof child === 'string') {
            // if it's a plain string
            return renderSplitText(i, child);
          } else {
            const { text, markType, children } = child.props;

            // if it's a mark it will be a react node
            return cloneElement(child, {
              children: text ? renderSplitText(i, text, markType) : children,
              parentStyleKey: styleKey,
            });
          }
        })
        .flat()
    : children;

  // If we've formatted the block with multiple child elements, we need to
  // concatenate them into a single string for screen readers.
  const readableLabel =
    Array.isArray(children) &&
    children.length > 1 &&
    children
      ?.map((child) => {
        if (typeof child === 'string') {
          return child;
        } else {
          const { text, children } = child.props;
          return text || children;
        }
      })
      .join(' ')
      .trim()
      .replace(/\s+/g, ' ');

  return (
    <RenderTag
      className={renderClass}
      aria-label={readableLabel || undefined}
      ref={(element: HTMLElement) => {
        $element.current = element;
        return refs && element && addToRefArray({ element, refArray: refs });
      }}
      {...attributes}
    >
      {readableLabel ? <span aria-hidden="true">{text}</span> : text}
    </RenderTag>
  );
};

export default BlockBase;
