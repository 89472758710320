import { useRef } from 'react';

import styles from '~/components/molecules/SplitText/SplitText.module.css';
import { SplitTextProps } from '~/components/molecules/SplitText/SplitText.types';
import {
  handleSplitLetters,
  handleSplitWords,
  isEmptySpace,
} from '~/components/molecules/SplitText/utils/handleSplitText';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

const SplitText = ({ blockRefs, index, trim, children }: SplitTextProps) => {
  const letterRefs = useRef([]);
  const wordRefs = useRef([]);

  const shouldSplit = typeof blockRefs !== 'undefined';

  const text =
    typeof children === 'string' && shouldSplit
      ? handleSplitWords(children).map((word) => {
          return word;
        })
      : children;

  const renderLetters = (word: string) => {
    const letters = handleSplitLetters(word);
    return letters.map((letter, i) => {
      return (
        <span
          aria-disabled
          key={`letter-${i}`}
          className={styles.letter}
          ref={(element: HTMLElement) => {
            if (element && blockRefs) {
              blockRefs.current?.[index].letterEls?.push(element);

              if (
                blockRefs.current?.[index].markType === 'openQuote' &&
                !blockRefs.current?.[index].openQuoteMarkEl
              ) {
                blockRefs.current[index].openQuoteMarkEl = element;
              }
              return addToRefArray({
                element,
                refArray: letterRefs,
              });
            }
          }}
        >
          {letter}
        </span>
      );
    });
  };

  return Array.isArray(text)
    ? text.map((word, i) => {
        return word && typeof word === 'string' ? (
          <span
            className={cn(isEmptySpace(word) ? styles.emptySpace : styles.word)}
            key={`word-${i}`}
            ref={(element: HTMLElement) => {
              if (!isEmptySpace(word)) {
                if (element && blockRefs) {
                  blockRefs.current?.[index].wordEls?.push(element);
                  return addToRefArray({
                    element,
                    refArray: wordRefs,
                  });
                }
              }
            }}
          >
            {isEmptySpace(word) && trim !== true ? ' ' : renderLetters(word)}
          </span>
        ) : (
          word
        );
      })
    : typeof text === 'string' && text;
};

export default SplitText;
